/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type categories_categories$ref: FragmentReference;
declare export opaque type categories_categories$fragmentType: categories_categories$ref;
export type categories_categories = {|
  +categories: ?$ReadOnlyArray<?{|
    +slug: string,
    +details: ?$ReadOnlyArray<?string>,
  |}>,
  +$refType: categories_categories$ref,
|};
export type categories_categories$data = categories_categories;
export type categories_categories$key = {
  +$data?: categories_categories$data,
  +$fragmentRefs: categories_categories$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "categories_categories",
  "selections": [
    {
      "alias": "categories",
      "args": null,
      "concreteType": "Category",
      "kind": "LinkedField",
      "name": "getCategories",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "details",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '4b5c97b0e72b13222e542c061ecd2ae5';

module.exports = node;
