import dayjs from '@/helpers/dayjs';

class CalendarStore {
  constructor({ datepicked, period }) {
    this.period = { ...period };
    this.datepicked = [...datepicked];
  }

  getPeriod() {
    return this.period || {};
  }

  getDatesPicked() {
    return this.datepicked || [];
  }

  setPeriod(value) {
    this.period = value;
    return this;
  }

  setDatesPicked(value) {
    this.datepicked = value;
    return this;
  }

  reset() {
    this.period = [];
    this.datepicked = [];
  }

  getDaysInMonth2(month, year) {
    // eslint-disable-next-line no-unused-vars
    const self = this;
    let start = dayjs().year(year).month(month).startOf('month');

    let end = dayjs(start).endOf('month');
    // on se met à lundi suivant le premier jour du mois
    let nextMonday = (7 - parseInt(start.format('d'), 10)) + 1;

    // puis on se met à last lundi pour avoir une ligne calendar qui comprend le debut du mois
    start = start.add(nextMonday, 'day').subtract(7, 'day');

    // on se met au dernier dimanche du mois
    nextMonday = (7 - parseInt(end.format('d'), 10));
    end = end.add(nextMonday, 'day');
    return [start, end];
  }

  getDaysInMonth(month, year) {
    const result = this.getDaysInMonth2(month, year);
    let date = result[0];
    const max = result[1];
    const days = [];
    // on parcours jusquà la fin entre start et end
    let i = 0;
    while (date.diff(max) <= 0 && i < 50) {
      let active = true;
      if (date.month() !== month) {
        active = false;
      }
      const newDate = dayjs(date).startOf('day');
      const day = {
        date: newDate,
        active,
      };
      days.push(day);
      date = date.add(1, 'day');
      i += 1;
    }
    return days;
  }

  isPast(date) {
    // eslint-disable-next-line no-unused-vars
    const self = this;
    let today = dayjs();
    today = today.startOf('day');
    let o = dayjs(date);
    if (typeof date === 'string' || date instanceof String) {
      o = dayjs(date);
    }
    o = o.startOf('day');
    return (o.diff(today) < 0);
  }
}

export default CalendarStore;
