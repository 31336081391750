import {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import { graphql, useFragment } from 'react-relay';
import { useRouter } from 'next/navigation';
import { useRouter as useNextRouter } from 'next/router';
import Icon from '@/core/icon';
import { useTranslation, lang } from '@/helpers/translate';
import miniqlv3 from '@/helpers/miniQueryLoaderv3';

const fragmentCate = graphql`
  fragment categories_categories on Query {
    categories : getCategories {
      slug
      details
    }
  }
`;

const query = graphql`
  query categoriesQuery {
    ...categories_categories
  }
`;

function Categories({ data, fwdRef, callback }) {
  const { categories } = data || {};
  const { t } = useTranslation();
  const router = useRouter();
  const routerNext = useNextRouter();
  const slugs = t('category:slug', {}, { returnObjects: true });
  let categoryslug = routerNext?.query?.route || t('category:slug.meeting');
  categoryslug = Object.entries(slugs).find(([cate, catetrad]) => catetrad === categoryslug); // eslint-disable-line no-unused-vars, max-len
  categoryslug = categoryslug ? categoryslug[0] : 'meeting';

  const cateDefault = (categories || []).find((cate) => cate.slug === categoryslug);

  const [cateSelected, setCateSelected] = useState(cateDefault);

  function getValue() {
    return cateSelected?.slug;
  }

  useImperativeHandle(fwdRef, () => ({
    getValue,
  }), [cateSelected]);

  useEffect(() => {
    if (callback) {
      callback(cateSelected?.slug);
    }
  }, [cateSelected]);

  let subs = t(`category:${cateSelected?.slug || ''}_sub`).split('-').map((s) => s.trim()).filter((s) => s);
  const funcGetSubs = (cateS) => (cateS?.slug !== cateSelected?.slug ? [] : t(`category:${cateS?.slug || ''}_sub`).split('-').map((s) => s.trim()).filter((s) => s));
  if (!subs) subs = [];

  if (!data || !categories || !cateSelected) return null;

  return (
    <div className="">
      <ul className="grid grid-cols-3 gap-x-2 cursor-pointer h-[80px] lg:h-[74px] px-3 lg:px-6">
        {categories.map((cate) => (
          <li
            key={cate.slug}
            onClick={() => {
              setCateSelected(cate);
            }}
            className="items-center transition duration-300 group hover:border-blue"
          >
            <div className={`hidden lg:block transition w-full h-[2px] rounded-full ${cate.slug === cateSelected.slug ? 'bg-black' : ''}`} />
            <div className="flex flex-col items-center h-full pt-3 pb-1 lg:flex-row lg:flex-grow lg:pb-0 lg:pt-2">
              <div className="h-10 lg:h-9 lg:w-14 lg:flex lg:items-center">
                <Icon name={`category-${cate.slug}`} className={`w-9 h-9 lg:w-12 lg:h-12 fill-current transition duration-300 ${cate.slug === cateSelected.slug ? 'text-black' : 'text-dark-light group-hover:text-black-light'} `} />
              </div>
              <div className="flex flex-col pt-1 pb-0 text-left lg:pt-0 lg:pl-1">
                <div className={`leading-none transition duration-300 ${cate.slug === cateSelected.slug ? 'text-black font-medium' : 'text-dark-light group-hover:text-black-light  '}`}>{t(`category:${cate.slug}`)}</div>
                <div className="hidden text-balance lg:flex flex-wrap pt-[2px]">
                  {funcGetSubs(cate).map((d, i) => (<span key={`fieldcategoriessubs${d}`} className={`text-[13px] leading-tight text-dark ${i < subs.length - 1 ? 'first:pl-0 pl-1 after:content-[","]' : ''}`}>
                    {d}
                                                    </span>
                  ))}
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

const MiniQueryLoaderCategories = miniqlv3({
  Component: ({ data, ...props }) => {
    const dataFrag = useFragment(fragmentCate, data);
    return <Categories data={dataFrag} {...props} />;
  },
  query,
});

export default forwardRef((props, ref) => <MiniQueryLoaderCategories fwdRef={ref} {...props} />);

export {
  fragmentCate,
  query,
};
