/* eslint-disable no-param-reassign */
import { fitBounds } from 'google-map-react';
import { fetchQuery } from 'react-relay';
import { querySearchPlaceId } from '@/core/search.js';

import {
  initEnvironment,
} from '@/relay/index.js';

// eslint-disable-next-line max-len
export const getNewBounds = ({ bounds, width, height }) => fitBounds({ ...bounds }, { width, height });

// eslint-disable-next-line camelcase
export const getPlaceData = ({ place_id }) => new Promise((resolved) => {
  // eslint-disable-next-line camelcase
  fetchQuery(initEnvironment(), querySearchPlaceId, { place_id }, { force: true }).subscribe({
    next: (res) => {
      if (!res?.res?.location?.lat || !res?.res?.location?.lng) return resolved(false);
      return resolved(res?.res);
    },
    error: (error1) => {
      console.log('ERROR helpers/position getPlaceData');
      return resolved(false);
    },
  });
});

const getPosition = (markerRef, mapRef, mobile, mono) => {
  const eleRect = markerRef.current.getBoundingClientRect();
  const mapRect = mapRef.current.getBoundingClientRect();
  const top = eleRect.top - mapRect.top;
  const left = eleRect.left - mapRect.left;
  // console.log(top, 'top', mapRect.top);

  const limitRight = left + eleRect.width / 2 + 10;
  const limitLeft = left - eleRect.width / 2 - 7;
  const limitBottom = top + eleRect.height;

  let pourOver = '0%'; let
    extraRight = 0;

  if (limitRight > mapRect.width - 50) {
    extraRight = mapRect.width - limitRight - 50;
  } else if (limitLeft > 0 && limitLeft < 60) {
    extraRight = 60 - limitLeft;
  } else if (limitLeft <= 0) {
    extraRight = -limitLeft + 60;
  }
  let extraTop = 10;
  if (limitBottom > mapRect.height) {
    pourOver = '-100%';
    extraTop = mono ? -40 : -20;
    if (top - 40 - eleRect.height > 0 && top - 40 - eleRect.height < 50) {
      extraTop = -(top - eleRect.height) + 50;
    } else if (top - 40 - eleRect.height < 0) {
      extraTop = -(top - eleRect.height - 50);
    }
  }
  if (mobile) {
    markerRef.current.style.position = 'fixed';
    markerRef.current.classList.add('popin-search-responsive');
    markerRef.current.style.transform = `translate(${-(left - 20)}px, calc(var(--heightScreen) - ${eleRect.top + eleRect.height + 130}px))`;
    markerRef.current.style.width = 'calc(100vw - 40px)';
  } else {
    const translateXY = [
      `translate(calc(-50% + ${extraRight}px), calc(${pourOver} + ${extraTop}px))`,
    ];
    markerRef.current.style.transform = translateXY.join(' ');
  }

  markerRef.current.classList.remove('invisible');
};

export default getPosition;
