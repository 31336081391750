import { useState } from 'react';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import { useTranslation } from '@/helpers/translate';
import Icon from '@/core/icon';
import DateByDay from './choiceByDay.js';

function Calendar({ store, onSelected }) {
  const { t } = useTranslation();
  const [reactKey] = useState(nanoid());

  let current = dayjs();
  current.startOf('day');
  const endMonth = dayjs().endOf('month');
  if (current.isSame(endMonth, 'day')) {
    current = endMonth.add(1, 'days');
  }

  const [dataCurrent, setDataCurrent] = useState({
    date: current,
    calendar: store.getDaysInMonth(current.month(), current.year()),
  });

  function nextMonth(event) {
    if (event) event.preventDefault();
    const next = dayjs(dataCurrent.date).add(1, 'month');

    setDataCurrent({
      date: next,
      calendar: store.getDaysInMonth(next.month(), next.year()),
    });
  }

  function prevMonth(event) {
    if (event) event.preventDefault();
    const prev = dayjs(dataCurrent.date).subtract(1, 'month');

    setDataCurrent({
      date: prev,
      calendar: store.getDaysInMonth(prev.month(), prev.year()),
    });
  }

  const splitCalendar = () => {
    const { calendar: newCalendar } = dataCurrent;
    let start = 0;
    let max = 7;
    const splitCalendarRes = [];
    while (newCalendar[start]) {
      const row = [];
      for (let i = start; i < max; i += 1) {
        row.push(newCalendar[i]);
      }
      splitCalendarRes.push(row);
      start += 7;
      max += 7;
    }

    return splitCalendarRes;
  };

  function renderDay(item) {
    return (
      <DateByDay
        key={`cmpsearcgfiltercalendarchoiceByDay${reactKey}${item.date.valueOf()}`}
        {...{
        item,
        store,
        calendarCurrent: dataCurrent,
        onSelected,
      }}
      />
    );
  }

  function renderLine(line) {
    const { date: cur } = dataCurrent;
    return line.map((item) => {
      if (item && cur.month() === item.date.month()) {
        return renderDay(item);
      }
      return (
        <td
          className="w-1/7 text-center cursor-default text-dark-light"
          key={`cmpsearcgfiltercalendarlineindex${reactKey}${item.date.valueOf()}`}
          data-disabled="true"
        >
          <div className=""><span /></div>
        </td>
      );
    });
  }

  function renderCalendar() {
    return splitCalendar().map((line, index) => (
      <tr key={`cmpsearcgfiltercalendarcalendar${reactKey}${line?.[0]?.date.valueOf()}`}>
        {renderLine(line, index)}
      </tr>
    ));
  }

  function renderMonth() {
    const { date: cur } = dataCurrent;
    const today = dayjs();
    today.startOf('day');
    if (cur.year() === today.year()) {
      return t(`date:month.${cur.month()}`);
    }

    return t('date:month.with_year', { month: t(`date:month.${cur.month()}`), year: cur.year() });
  }

  function renderPrev() {
    const now = dayjs();
    if (dataCurrent.date.diff(now) > 0) {
      return (
        <button className="flex justify-center items-center w-9 h-9 rounded-full bg-white-dark hover:bg-white-dark3" aria-label="prev" onClick={(event) => prevMonth(event)}>
          <Icon name="next" className="transform rotate-180 w-5 h-5" />
        </button>
      );
    }
    return null;
  }

  return (
    <div className="lg:mt-0 mb-2">
      <div className="flex items-center w-full pb-2">
        <div className="w-9 flex-none">
          {renderPrev()}
        </div>
        <div className="text-center flex-grow text-lg">
          {renderMonth()}
        </div>
        <div className="flex-none">
          <button className="flex justify-center items-center w-9 h-9 rounded-full bg-white-dark hover:bg-white-dark3" aria-label="next" onClick={(event) => nextMonth(event)}>
            <Icon name="next" className="w-5 h-5" />
          </button>
        </div>
      </div>
      <div className="row">
        <div className="">
          <table className="w-full">
            <thead>
              <tr className="font-normal text-center">
                <th className="text-center"><span className="font-normal text-xs text-dark">{t('date:day.mon')}</span></th>
                <th className="text-center"><span className="font-normal text-xs text-dark">{t('date:day.tue')}</span></th>
                <th className="text-center"><span className="font-normal text-xs text-dark">{t('date:day.wed')}</span></th>
                <th className="text-center"><span className="font-normal text-xs text-dark">{t('date:day.thu')}</span></th>
                <th className="text-center"><span className="font-normal text-xs text-dark">{t('date:day.fri')}</span></th>
                <th className="text-center"><span className="font-normal text-xs text-dark">{t('date:day.sat')}</span></th>
                <th className="text-center"><span className="font-normal text-xs text-dark">{t('date:day.sun')}</span></th>
              </tr>
            </thead>
            <tbody>
              {renderCalendar()}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Calendar;
