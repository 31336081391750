/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type searchQueryVariables = {|
  place_id: string
|};
export type searchQueryResponse = {|
  +res: ?{|
    +location: ?{|
      +formatted_address: ?string,
      +lat: ?number,
      +lng: ?number,
      +viewport: ?{|
        +northeast: ?{|
          +lat: ?number,
          +lng: ?number,
        |},
        +southwest: ?{|
          +lat: ?number,
          +lng: ?number,
        |},
      |},
    |}
  |}
|};
export type searchQuery = {|
  variables: searchQueryVariables,
  response: searchQueryResponse,
|};
*/


/*
query searchQuery(
  $place_id: String!
) {
  res: geoGetByPlaceId(place_id: $place_id) {
    location {
      formatted_address
      lat
      lng
      viewport {
        northeast {
          lat
          lng
        }
        southwest {
          lat
          lng
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "place_id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "place_id",
    "variableName": "place_id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "formatted_address",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lat",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lng",
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "GeoPlaceViewport",
  "kind": "LinkedField",
  "name": "viewport",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "GeoLatLng",
      "kind": "LinkedField",
      "name": "northeast",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GeoLatLng",
      "kind": "LinkedField",
      "name": "southwest",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "searchQuery",
    "selections": [
      {
        "alias": "res",
        "args": (v1/*: any*/),
        "concreteType": "Place",
        "kind": "LinkedField",
        "name": "geoGetByPlaceId",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Location",
            "kind": "LinkedField",
            "name": "location",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "searchQuery",
    "selections": [
      {
        "alias": "res",
        "args": (v1/*: any*/),
        "concreteType": "Place",
        "kind": "LinkedField",
        "name": "geoGetByPlaceId",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Location",
            "kind": "LinkedField",
            "name": "location",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4ba519b1418a1f067eb25d005c53a1a5",
    "id": null,
    "metadata": {},
    "name": "searchQuery",
    "operationKind": "query",
    "text": "query searchQuery(\n  $place_id: String!\n) {\n  res: geoGetByPlaceId(place_id: $place_id) {\n    location {\n      formatted_address\n      lat\n      lng\n      viewport {\n        northeast {\n          lat\n          lng\n        }\n        southwest {\n          lat\n          lng\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '33855dc6c3988ac69f0547a03242c119';

module.exports = node;
