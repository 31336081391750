import {
  useReducer, useEffect, useRef,
  forwardRef, useImperativeHandle,
  useCallback,
} from 'react';
import { nanoid } from 'nanoid';
import get from '@/helpers/getValue';
import GoogleLists from '@/components/fields/hubs/googleLists.js';
import HubsLists from '@/components/fields/hubs/lists.js';
import { useTranslation } from '@/helpers/translate';
import { useDebounce } from '@/helpers/common';
import Emitter from '@/helpers/emitter.js';

const Hubs = forwardRef(({
  onSelected, fromSearchPage, defaultTitle, classesDefault, city,
}, ref) => {
  const { t } = useTranslation();
  const inputRef = useRef();
  const divRootRef = useRef();
  const googleListsRef = useRef();
  const hubsListsRef = useRef();

  // according to value input do action
  const [state, setState] = useReducer((state, action) => {
    const newState = { ...state };
    if (action.event && action.event.preventDefault) {
      action.event.preventDefault();
    }
    if (action.event && action.event.stopPropagation) {
      action.event.stopPropagation();
    }
    newState.type = action.type;
    newState.runGoogle = false;
    newState.openHub = false;
    if (action.type === 'inputChange') {
      newState.inputValue = inputRef.current.value;
      // input empty => show hub, close google list
      if (!inputRef.current.value) {
        newState.runGoogle = nanoid();
        newState.openHub = true;
      }
    } else if (action.type === 'choose') {
      // a hub is chosing => show description
      newState.hubSelected = action.newHub;
      // eslint-disable-next-line max-len
      inputRef.current.value = newState.hubSelected?.location?.formatted_address || newState?.hubSelected?.title;
      newState.runGoogle = nanoid();
    } else if (action.type === 'onFocus') {
      // onfocus input, if empty then open hubs
      if (!inputRef.current.value) newState.openHub = true;
      else newState.runGoogle = nanoid();
    }
    return { ...newState };
    // eslint-disable-next-line max-len
  }, { inputValue: defaultTitle || '', openHub: null, runGoogle: fromSearchPage ? 'initfromsearchpage' : null });

  // wait until typing end=>concerned useeffect will called
  const debounceInputValue = useDebounce(state.inputValue, 500);

  // set open/close hub lists
  function setHubsLists(value) {
    hubsListsRef?.current?.setOpen?.(() => value);
  }
  // set/show google results after input
  function setGoogleListsValue(value) {
    googleListsRef?.current?.setPattern?.(() => value);
  }

  // if hub change by click , hub bdd or google
  useEffect(() => {
    if (onSelected) onSelected(state.hubSelected);
    if (fromSearchPage && state.type) {
      Emitter.emit('headersearchpagechangehub', state.hubSelected);
    }
  }, [state.hubSelected]);

  // if input change, clean hub selected, user need select one
  useEffect(() => {
    if (onSelected) onSelected(null);
  }, [state.inputValue]);

  const handleClickOutside = useCallback((event) => {
    if (divRootRef.current && !divRootRef.current.contains(event.target) && state.openHub) {
      setState({ type: 'closeHubs' });
    }
    return true;
  });

  // close hubs if click outside
  useEffect(() => {
    if (state.openHub !== null) {
      if (state.openHub) {
        window.addEventListener('mousedown', handleClickOutside);
      }
      setHubsLists(state.openHub);
      return () => window.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {};
  }, [state.openHub]);

  // debouce google search after input
  useEffect(() => {
    if (fromSearchPage && state.runGoogle === 'initfromsearchpage') return;

    if (debounceInputValue !== null) {
      if (debounceInputValue) setGoogleListsValue(debounceInputValue);
      else setGoogleListsValue('');
    }
  }, [debounceInputValue]);

  useEffect(() => {
    if (state.runGoogle && state.runGoogle !== 'initfromsearchpage') {
      if (state.type === 'choose') setGoogleListsValue('');
      else setGoogleListsValue(inputRef.current.value);
    }
  }, [state.runGoogle]);

  function getValueDefault(cityDefault) {
    if (get(hubsListsRef, 'current.getValueDefault')) {
      return hubsListsRef.current.getValueDefault(cityDefault);
    }
    return {
      slug: 'paris',
      title: 'Paris',
      location: {
        formatted_address: 'Paris, France',
        lat: 48.856614,
        lng: 2.3522219000000177,
      },
    };
  }
  function getValue() {
    return state.hubSelected;
  }

  useImperativeHandle(ref, () => ({
    getValue,
    getValueDefault,
  }), [state]);

  function capitalizeFirstLetter(string) {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const cityGuides = `${capitalizeFirstLetter(city)}, France`;

  return (
    <div className={classesDefault?.container || 'min-w-44 relative border border-white-dark4 shadow-light mb-2 rounded-lg lg:border-none lg:shadow-none lg:rounded-none lg:mb-0 lg:border-b-0'} ref={divRootRef}>
      {fromSearchPage || <label htmlFor="street_address" className="text-left block text-[14px] text-black pt-2 lg:pt-3 pb-0 px-4">{t('core:where')}</label>}
      <div className="relative">
        <input
          type="search"
          name="street_address"
          id="street_address"
          className={classesDefault?.input || 'bg-transparent lg:relative lg:bottom-[1px] lg:h-12 pt-0 pb-2 lg:pb-2 px-4 placeholder-dark md:text-lg text-black block w-full border-none focus:ring-0'}
          placeholder={t('core:where_to_ride')}
          onChange={(event) => setState({ type: 'inputChange', event })}
          ref={inputRef}
          onFocus={(event) => setState({ type: 'onFocus', event })}
          autoComplete="off"
          defaultValue={city ? cityGuides : state.inputValue}
        />
        {/* {classesDefault?.input ? '' :
          <div className="absolute top-[12px] md:top-[12px] left-3">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-dark">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
            </svg>
          </div>
        } */}
      </div>
      <HubsLists fwdRef={hubsListsRef} onClick={(hub, event) => setState({ type: 'choose', newHub: hub, event })} />
      <GoogleLists ref={googleListsRef} onClick={(hub, event) => setState({ type: 'choose', newHub: hub, event })} />
    </div>
  );
});

export default Hubs;
