/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type googleLists_lists$ref: FragmentReference;
declare export opaque type googleLists_lists$fragmentType: googleLists_lists$ref;
export type googleLists_lists = {|
  +lists: ?$ReadOnlyArray<?{|
    +gg_place_id: ?string,
    +slug: ?string,
    +title: ?string,
  |}>,
  +$refType: googleLists_lists$ref,
|};
export type googleLists_lists$data = googleLists_lists;
export type googleLists_lists$key = {
  +$data?: googleLists_lists$data,
  +$fragmentRefs: googleLists_lists$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pattern"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "googleLists_lists",
  "selections": [
    {
      "alias": "lists",
      "args": [
        {
          "kind": "Variable",
          "name": "pattern",
          "variableName": "pattern"
        }
      ],
      "concreteType": "Place",
      "kind": "LinkedField",
      "name": "getPlacesByPattern",
      "plural": true,
      "selections": [
        {
          "alias": "gg_place_id",
          "args": null,
          "kind": "ScalarField",
          "name": "place_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '7b3fe8c171992b36a633d5c0ce0987e2';

module.exports = node;
