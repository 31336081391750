/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
import type { googleLists_lists$ref } from "./googleLists_lists.graphql";
export type googleListsQueryVariables = {|
  pattern: string
|};
export type googleListsQueryResponse = {|
  +$fragmentRefs: googleLists_lists$ref
|};
export type googleListsQuery = {|
  variables: googleListsQueryVariables,
  response: googleListsQueryResponse,
|};
*/


/*
query googleListsQuery(
  $pattern: String!
) {
  ...googleLists_lists_1Am0eh
}

fragment googleLists_lists_1Am0eh on Query {
  lists: getPlacesByPattern(pattern: $pattern) {
    gg_place_id: place_id
    slug
    title
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pattern"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "pattern",
    "variableName": "pattern"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "googleListsQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "googleLists_lists"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "googleListsQuery",
    "selections": [
      {
        "alias": "lists",
        "args": (v1/*: any*/),
        "concreteType": "Place",
        "kind": "LinkedField",
        "name": "getPlacesByPattern",
        "plural": true,
        "selections": [
          {
            "alias": "gg_place_id",
            "args": null,
            "kind": "ScalarField",
            "name": "place_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a8c1cc42a6a41a9689562026e67e1eb1",
    "id": null,
    "metadata": {},
    "name": "googleListsQuery",
    "operationKind": "query",
    "text": "query googleListsQuery(\n  $pattern: String!\n) {\n  ...googleLists_lists_1Am0eh\n}\n\nfragment googleLists_lists_1Am0eh on Query {\n  lists: getPlacesByPattern(pattern: $pattern) {\n    gg_place_id: place_id\n    slug\n    title\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8cdd86300da0ec6338a0e553ea081338';

module.exports = node;
