/* eslint-disable no-param-reassign */
import dayjs from 'dayjs';

const PluginDAYJSFORMATASKEY = (option, dayjsClass) => {
  // eslint-disable-next-line no-param-reassign
  dayjsClass.prototype.formatAsKey = function () {
    return this.format('YYYYMMDDTHHmmss,SSS');
  };
  dayjsClass.prototype.orformat = function (format = 'YYYYMMDD') {
    return this.format(format);
  };
};

dayjs.extend(PluginDAYJSFORMATASKEY);

export default dayjs;
