import {
 useState, useRef, useEffect, useCallback,
} from 'react';
import { useRouter } from 'next/compat/router';
import { graphql } from 'react-relay';
import Icon from '@/core/icon.js';
import get from '@/helpers/getValue';
import { useTranslation } from '@/helpers/translate';
import Hubs from '@/components/fields/hubs.js';
import Categories from '@/components/fields/categories.js';
import Riders from '@/components/fields/riders.js';
import { useZusTrackerStore } from '@/helpers/tracker.js';
import { getPlaceData } from '@/helpers/position.js';
import cookie from '@/helpers/cookie';
import { Button } from '@/core/dynamic';
import Calendar from '@/components/fields/calendar/index.js';

export const querySearchPlaceId = graphql`
  query searchQuery ($place_id: String!) {
    res: geoGetByPlaceId(place_id: $place_id) {
      location {
        formatted_address
        lat
        lng
        viewport {
          northeast {
            lat
            lng
          }
          southwest {
            lat
            lng
          }
        }
      }
    }
  }
`;

export default function Search({
  callback: _callback, isCms, isPopin, onClose, moreHtml = {}, cityDefault, categoryDefault,
}) {
  const { t } = useTranslation();
  const router = useRouter();
  const { setOrEvent } = useZusTrackerStore.getState();
  const [clicked, setClicked] = useState(false);
  const divRefs = {
    hubs: useRef(null),
    calendar: useRef(null),
    riders: useRef(null),
  };
  const [calendarHovered, setCalendarHovered] = useState(false);
  const [ridersHovered, setRidersHovered] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      Object.keys(divRefs).forEach((key) => {
        if (divRefs[key].current && !divRefs[key].current.contains(event.target)) {
          setClicked((prevState) => ({
            ...prevState,
            [key]: false,
          }));
        }
      });
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [divRefs]);

  const handleClick = (key) => {
    setClicked((prevState) => ({
      ...prevState,
      [key]: true,
    }));
  };

  const refs = {
    refImg: useRef(),
    refRiders: useRef(),
    refCategories: useRef(),
    refHubs: useRef(),
    refCal: useRef(),
  };
  const [error, setError] = useState();
  const [categoriesLoaded, setCategoriesLoaded] = useState(false);

  const callbackCate = useCallback((cate) => {
    refs?.refRiders?.current?.setCategory?.(cate);
    if (_callback) _callback(cate);
  });

  useEffect(() => {
    if (refs.refCategories) {
      setCategoriesLoaded(true);
    }
  }, [refs.refCategories]);

  // for homepage
  const skin = {
    lvl0: 'flex mx-auto w-full',
    lvl1: 'flex justify-center w-full lg:pb-0',
    lvl2: 'z-10 bg-white shadow-lg rounded-xl w-full mx-6 lg:mx-0 lg:w-[800px]',
  };
  if (isCms) {
    skin.lvl0 = 'pt-1 lg:pt-2 flex mx-auto w-full';
    skin.lvl1 = 'flex justify-center w-full pb-0';
    skin.lvl2 = 'z-10 bg-white shadow-lg rounded-2xl w-full p-3 lg:p-0 mx-6 lg:mx-0 lg:w-[780px]';
  }
  if (isPopin) {
    skin.lvl2 = 'bg-white w-screen h-dvh flexjustify-center lg:h-fit lg:shadow-2xl lg:rounded-2xl sm:w-full md:min-w-450 md:max-w-[800px] lg:w-[850px] pt-20 lg:pt-0';
  }

  async function gotoSearch() {
    setOrEvent('search');
    const hubDefault = get(refs, 'refHubs.current.getValueDefault') ? refs.refHubs.current.getValueDefault(cityDefault) : false;
    const riderDefault = get(refs, 'refRiders.current.getValueDefault') ? refs.refRiders.current.getValueDefault() : false;

    const cate = !(get(refs, 'refCategories.current.getValue') && refs.refCategories.current.getValue()) ? (categoryDefault || 'meeting') : refs.refCategories.current.getValue();
    const hub = !(get(refs, 'refHubs.current.getValue') && refs.refHubs.current.getValue()) ? hubDefault : refs.refHubs.current.getValue();
    const riders = !(get(refs, 'refRiders.current.getValue') && refs.refRiders.current.getValue()) ? riderDefault : refs.refRiders.current.getValue();
    if (!cate || !hub) return setError('error, empty value');

    let resPlace = hub;
    if (hub.gg_place_id) {
      resPlace = await getPlaceData({ place_id: hub.gg_place_id });
      if (!resPlace) return alert('Please contact OfficeRider client support');
    }
    const dateData = refs?.refCal?.current?.getOutput?.() || null;

    /*
    let url = `${process.env.NEXT_PUBLIC_URL_V3}${langCurrent !== 'en' ? `/${langCurrent}`:''}/
    ${trad[langCurrent].location}-${hub.slug}/
    ${slugs[cate]}?address=${resPlace.location.formatted_address}&lat=${resPlace.location.lat}&
    lng=${resPlace.location.lng}&riders=${!riders ? '' : riders.value}&
    page=1&hub=${hub.slug}&maxDist=5.218108265508237&maxDistNext=10.436216531016473`

    let url = `${langCurrent !== 'en' ? `/${langCurrent}`:''}/s/${hub.slug}?
    category=${cate}&title=${resPlace.location.formatted_address}&
    lat=${resPlace.location.lat}&
    lng=${resPlace.location.lng}${!riders ? '' : `&riders=${riders.value}`}&page=1&hub=${hub.slug}`

    {`${process.env.NEXT_PUBLIC_URL_V4}/fr/location-marseille/reunion?address=Marseille%2C%20France&
    lat=43.296482&lng=5.369779999999992&zoom=12&kmv3=WcBtkW3S6L4zkWmN4&riders=16&
    boundSLat=43.1632777837433&boundSLng=5.30537479713439&boundNLat=43.42939506230421&
    boundNLng=5.434185202865591&page=1&hub=marseille&category=meeting&
    maxDist=5.218108265508237&maxDistNext=10.436216531016473
    router.push(url, )
    */
    const searchurl = JSON.parse(cookie.get(`urlSearch${hub.slug}${cate}${riders.value}`));
    let routerData;
    if (searchurl) {
      routerData = {
        pathname: '/s/[location]',
        query: {
          location: searchurl.hub,
          ...searchurl,
          ...(!dateData?.url ? {} : { dates: dateData?.url }),
        },
      };
    } else {
      routerData = {
        pathname: '/s/[location]',
        query: {
          location: hub.slug,
          category: cate,
          title: resPlace.location.formatted_address,
          lat: resPlace.location.lat,
          lng: resPlace.location.lng,
          ...(!riders ? {} : { riders: riders.value }),
          page: 1,
          hub: hub.slug,
          ...(!dateData?.url ? {} : { dates: dateData?.url }),
        },
      };
    }

    if (isPopin && onClose) {
      onClose?.(routerData);
    } else if (routerData) {
      setTimeout(() => {
        router.push(routerData);
      }, 0);

      // setTimeout(()=>router.reload());
    }

    return true;
  }
  function onClickSearch() {
    return new Promise((resolve) => {
      gotoSearch(() => {
        resolve();
      });
    });
  }
  return (
    <div className={skin.lvl0}>
      <div className={skin.lvl1}>
        <div className={skin.lvl2}>
          <div className="hidden h-12 mx-6 modal-icon mt-14">
            <Icon className="w-12 h-12 text-white fill-current" name="logo-officeriders-min" />
          </div>
          {moreHtml.title}
          <div className="w-full rounded-t-xl md:rounded-b-xl md:rounded-t-none">
            {moreHtml.subtitle || null}
            {!isCms && (
              !categoriesLoaded ? (
                <ul className="grid grid-cols-3 gap-x-2 cursor-pointer h-[80px] lg:h-[74px] px-3 lg:px-6">
                    <li>
                      <div className="flex flex-col items-center h-full pt-3 pb-1 lg:flex-row lg:flex-grow lg:pb-0 lg:pt-2">
                        <div className="h-10 w-10 lg:h-[53px] lg:w-11 bg-white-dark2 rounded-lg" />
                        <div className="flex flex-col pt-2 pb-2 text-left lg:pb-0 lg:pt-0 lg:pl-3">
                          <div className="w-16 h-3 leading-none transition duration-300 rounded lg:w-20 lg:h-4 bg-white-dark2" />
                          <div className="hidden text-balance lg:flex flex-wrap mt-[8px] bg-white-dark2 w-36 h-2 " />
                          <div className="flex-wrap hidden w-16 h-2 mt-1 text-balance lg:flex bg-white-dark2 " />
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex flex-col items-center h-full pt-3 pb-1 lg:flex-row lg:flex-grow lg:pb-0 lg:pt-2">
                        <div className="h-10 w-10 lg:h-[53px] lg:w-11 bg-white-dark2 rounded-lg" />
                        <div className="flex flex-col pt-2 pb-2 text-left lg:pb-0 lg:pt-0 lg:pl-3">
                        <div className="w-16 h-3 leading-none transition duration-300 rounded lg:w-20 lg:h-4 bg-white-dark2" />
                          <div className="hidden text-balance lg:flex flex-wrap mt-[8px] bg-white-dark2 w-36 h-2 " />
                          <div className="flex-wrap hidden w-16 h-2 mt-1 text-balance lg:flex bg-white-dark2 " />
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex flex-col items-center h-full pt-3 pb-1 lg:flex-row lg:flex-grow lg:pb-0 lg:pt-2">
                        <div className="h-10 w-10 lg:h-[53px] lg:w-11 bg-white-dark2 rounded-lg" />
                        <div className="flex flex-col pt-2 pb-2 text-left lg:pb-0 lg:pt-0 lg:pl-3">
                          <div className="w-16 h-3 leading-none transition duration-300 rounded lg:w-20 lg:h-4 bg-white-dark2" />
                          <div className="hidden text-balance lg:flex flex-wrap mt-[8px] bg-white-dark2 w-36 h-2 " />
                          <div className="flex-wrap hidden w-16 h-2 mt-1 text-balance lg:flex bg-white-dark2 " />
                        </div>
                      </div>
                    </li>
                </ul>
              ) : (
                <div className="h-[80px] lg:h-[74px]"><Categories callback={callbackCate} ref={refs.refCategories} /></div>
              )
            )}
            <div className={`${clicked.calendar || clicked.hubs || clicked.riders ? 'lg:bg-white-dark2' : ''} lg:border lg:shadow-light lg:border-white-dark3 rounded-xl items-center justify-center grid grid-cols-1 lg:grid-flow-col gap-x-0 ${isCms ? 'border-none shadow-none lg:m-0' : 'mx-4 md:mx-5 lg:mx-6 mb-4 lg:mb-6 lg:mt-2'}`}>
              <div
                ref={divRefs.hubs}
                /* eslint-disable no-nested-ternary */
                className={`${clicked.hubs ? 'lg:z-10 lg:bg-white lg:shadow-light lg:after:w-0' : 'lg:hover:bg-white-dark'} ${clicked.calendar ? '' : (calendarHovered || clicked.hubs ? 'lg:after:w-0' : 'lg:after:w-[1px]')} rounded-xl lg:hover:after:w-0 transition relative lg:after:absolute lg:after:right-0 lg:after:top-4 lg:after:bottom-4 lg:after:bg-white-dark4 lg:hover:rounded-xl lg:hover:focus:w-0 lg:focus:border-r lg:hover:border-white-dark4`}
                /* eslint-enable no-nested-ternary */
                onClick={() => handleClick('hubs')}
              >
                <Hubs
                  city={cityDefault}
                  ref={refs.refHubs}
                  onSelected={(hubselected) => {
                  if (hubselected) {
                    setClicked((prevState) => ({
                      ...prevState,
                      hubs: false,
                    }));
                    handleClick('calendar');
                    setCalendarHovered(true);
                    refs?.refCal?.current?.setIsOpenModal?.(true);
                  }
                }}
                />
              </div>

              <div
                ref={divRefs.calendar}
                /* eslint-disable no-nested-ternary */
                className={`${clicked.calendar ? 'lg:z-10 lg:bg-white lg:shadow-light lg:after:w-0' : 'lg:hover:bg-white-dark'} ${clicked.riders ? '' : (ridersHovered || clicked.calendar ? 'lg:after:w-0' : 'lg:after:w-[1px]')} rounded-xl lg:hover:after:w-0 transition cursor-pointer relative lg:after:absolute lg:after:right-0 lg:after:top-4 lg:after:bottom-4 lg:after:bg-white-dark4 lg:hover:rounded-xl lg:hover:focus:w-0 lg:focus:border-r lg:hover:border-white-dark4`}
                /* eslint-enable no-nested-ternary */
                onClick={() => handleClick('calendar')}
                onMouseEnter={() => setCalendarHovered(true)}
                onMouseLeave={() => setCalendarHovered(false)}
              >
                <Calendar forwardRef={refs.refCal} />
              </div>
              <div
                ref={divRefs.riders}
                /* eslint-disable no-nested-ternary */
                className={`${clicked.riders ? 'lg:z-10 lg:bg-white lg:shadow-light lg:after:w-0' : 'lg:hover:bg-white-dark lg:after:w-[1px]'} rounded-xl lg:hover:after:w-0 transition cursor-pointer relative lg:after:absolute lg:after:right-0 lg:after:top-4 lg:after:bottom-4lg:after:bg-white-dark4 lg:hover:rounded-xl lg:hover:focus:w-0 lg:focus:border-r lg:hover:border-white-dark4`}
                /* eslint-enable no-nested-ternary */
                onClick={() => handleClick('riders')}
                onMouseEnter={() => setRidersHovered(true)}
                onMouseLeave={() => setRidersHovered(false)}
              >
                <Riders ref={refs.refRiders} />
              </div>
              <div className="shrink lg:w-14 lg:mx-3 lg:my-0">
                <Button aria-label="search" name="search" className="flex items-center justify-center w-full py-3 text-lg text-white rounded-lg shrink lg:size-14 blue-gradient" onClick={() => onClickSearch()}>
                  <Icon name="search" className="w-5 text-white" />
                  <div className="inline ml-2 leading-none lg:hidden">{t('core:search')}</div>
                </Button>
              </div>
            </div>
            <div className="flex justify-center">
              <div className="mx-4 md:mx-6 w-full lg:mx-0 lg:w-[200px] relative top-0 lg:top-6" />
            </div>
          </div>
        </div>
      </div>
      {error}
      {moreHtml.footer || null}
    </div>
  );
}
