import {
  forwardRef, useState,
  useImperativeHandle, useEffect,
} from 'react';
import { nanoid } from 'nanoid';
import { graphql, useFragment } from 'react-relay';
import { Transition } from '@headlessui/react';
import Icon from '@/core/icon.js';
import miniQueryLoader from '@/helpers/miniQueryLoader';

const fragmentHubs = graphql`
  fragment lists_lists on Query {
    lists: getHubs {
      slug
      title
      location {
        formatted_address
        lat
        lng
      }
    }
  }
`;
const queryHubs = graphql`
  query listsQuery {
    ...lists_lists
  }
`;

function RenderLists({ onClick, fwdRef }) {
  const [open, setOpen] = useState(false);
  const [lists, setLists] = useState([]);

  function getValueDefault(slug) {
    return lists?.find((l) => l.slug === (slug || 'paris')) || {
      slug: 'paris',
      title: 'Paris',
      location: {
        formatted_address: 'Paris, France',
        lat: 48.856614,
        lng: 2.3522219000000177,
      },
    };
  }

  useImperativeHandle(fwdRef, () => ({
    setOpen,
    getValueDefault,
    setLists,
  }), [lists, open]);

  return (
    <Transition
      as="div"
      enter="transition ease-in duration-400"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in duration-400"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={open}
      className="absolute left-0 right-0 z-30 grid grid-cols-2 px-4 py-1 mx-0 mt-1 bg-white rounded-lg shadow-button lg:mt-2 sm:py-0 sm:px-0 sm:grid-cols-3 lg:gap-1 "
    >
      {
        (lists || []).map((l) => (
          <div className="flex items-center h-10 transition rounded-lg cursor-pointer group sm:h-24 sm:justify-center text-dark-deep hover:bg-white-dark" key={l.slug} onClick={(event) => onClick(l, event)}>
            <span className="flex flex-col items-center justify-center py-2 text-base sm:h-24 group-hover:text-black">
              <Icon className="hidden w-8 h-8 mb-2 fill-current sm:block group-hover:text-black" name={l.slug} />
              {l.title}
            </span>
          </div>
        ))
      }
    </Transition>
  );
}

const MiniQueryLoaderLists = miniQueryLoader({
  Component: ({ data, setData }) => {
    const dataFrag = useFragment(fragmentHubs, data);

    useEffect(() => {
      setData(dataFrag?.lists);
    }, [dataFrag]);
    return null;
  },
  query: queryHubs,
  fetchPolicy: 'store-or-network',
});

function ListenerHubs({ setData }) {
  const [loaded, setIsLoaded] = useState(false);

  return loaded || <MiniQueryLoaderLists
    key={nanoid()}
    setData={(value) => {
      setData(value);
      setIsLoaded(true);
    }}
  />;
}

export default function ComponentHubs({ fwdRef, ...props }) {
  return <>
    <RenderLists fwdRef={fwdRef} {...props} />
    <ListenerHubs setData={(value) => {
      fwdRef?.current?.setLists(value);
    }}
    />
         </>;
}
