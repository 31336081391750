/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type lists_lists$ref: FragmentReference;
declare export opaque type lists_lists$fragmentType: lists_lists$ref;
export type lists_lists = {|
  +lists: ?$ReadOnlyArray<?{|
    +slug: ?string,
    +title: ?string,
    +location: ?{|
      +formatted_address: ?string,
      +lat: ?number,
      +lng: ?number,
    |},
  |}>,
  +$refType: lists_lists$ref,
|};
export type lists_lists$data = lists_lists;
export type lists_lists$key = {
  +$data?: lists_lists$data,
  +$fragmentRefs: lists_lists$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "lists_lists",
  "selections": [
    {
      "alias": "lists",
      "args": null,
      "concreteType": "Hub",
      "kind": "LinkedField",
      "name": "getHubs",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Location",
          "kind": "LinkedField",
          "name": "location",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "formatted_address",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lat",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lng",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '2c77acad321028536277ef57ecd2bca7';

module.exports = node;
