/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
import type { lists_lists$ref } from "./lists_lists.graphql";
export type listsQueryVariables = {||};
export type listsQueryResponse = {|
  +$fragmentRefs: lists_lists$ref
|};
export type listsQuery = {|
  variables: listsQueryVariables,
  response: listsQueryResponse,
|};
*/


/*
query listsQuery {
  ...lists_lists
}

fragment lists_lists on Query {
  lists: getHubs {
    slug
    title
    location {
      formatted_address
      lat
      lng
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "listsQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "lists_lists"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "listsQuery",
    "selections": [
      {
        "alias": "lists",
        "args": null,
        "concreteType": "Hub",
        "kind": "LinkedField",
        "name": "getHubs",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Location",
            "kind": "LinkedField",
            "name": "location",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "formatted_address",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lat",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lng",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "428cce0ecec46aef5bd7a6fbca3677c6",
    "id": null,
    "metadata": {},
    "name": "listsQuery",
    "operationKind": "query",
    "text": "query listsQuery {\n  ...lists_lists\n}\n\nfragment lists_lists on Query {\n  lists: getHubs {\n    slug\n    title\n    location {\n      formatted_address\n      lat\n      lng\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '64a3a56acb83bbf2f2d72e421d693862';

module.exports = node;
