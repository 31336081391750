import { useEffect, useState, useRef } from 'react';
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { useTranslation } from '@/helpers/translate';
import dayjs from '@/helpers/dayjs';
import Icon from '@/core/icon';

function PeriodPopover({ item, onClose, store }) {
  const divRef = useRef();
  const { t } = useTranslation();
  const dateCurrent = dayjs(item.date);

  const [refLabel, setRefLabel] = useState();
  const [refPopover, setRefPopover] = useState();
  const { styles, attributes } = usePopper(refLabel, refPopover, { placement: 'bottom' });

  useEffect(() => {
    function handleClickOutside(event) {
      if (divRef && divRef.current && !divRef.current.contains(event.target)) {
        onClose();
      }
    }
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  function onClickPeriodSelect(_period) {
    if (dateCurrent) {
      const period = store.getPeriod();
      const current = store.getDatesPicked();
      if (_period === 'delete') {
        const index = current.indexOf(dateCurrent.formatAsKey());
        delete period[dateCurrent.formatAsKey()];
        current.splice(index, 1);
        // state.selected = false
      } else {
        // state.selected = true
        period[dateCurrent.formatAsKey()] = _period;
        if (current.indexOf(dateCurrent.formatAsKey()) === -1) {
          current.push(dateCurrent.formatAsKey());
        }
      }

      current.sort((d) => d);
      const df = Object.keys(period);
      df.sort((d) => dayjs(d));
      const ordered = df.reduce((acc, d) => {
        acc[d] = period[d];
        return acc;
      }, {});
      store.setDatesPicked(df);
      store.setPeriod(ordered);
    }
    onClose();
  }

  let selectPeriod = [];
  const add0 = true; const add1 = true; const add2 = true; const
    add3 = true;
  /*
  if (formuleSelected) {
    if (formuleSelected.type == "all") add0 = true
    if (formuleSelected.type == "night") add3 = true
    if (formuleSelected.type == "half" || formuleSelected.type == "half_morning") add1 = true
    if (formuleSelected.type == "half" || formuleSelected.type == "half_afternoon") add2 = true
  }
  */

  if (add0) selectPeriod.push({ text: 'day', value: 0 });
  if (add1) selectPeriod.push({ text: 'morning', value: 1 });
  if (add2) selectPeriod.push({ text: 'afternoon', value: 2 });
  if (add3) selectPeriod.push({ text: 'night', value: 3 });
  selectPeriod = selectPeriod.map((p) => <button key={p.text} onClick={() => { onClickPeriodSelect(p.value); }} className="w-32 p-2 hover:text-blue hover:bg-blue-light">{t(`btn:period.${p.text}`)}</button>);

  const isDeletable = store.getDatesPicked().indexOf(dateCurrent.formatAsKey()) !== -1;
  return (
    <Popover>
      <span ref={setRefLabel} />
      <Popover.Panel
        className="absolute z-10 block mt-1 transform -translate-x-1/2 left-1/2"
        static
        ref={setRefPopover}
        {...attributes.popper}
      >
        <div className="relative text-base bg-white border rounded-lg shadow-button border-white-dark3" ref={divRef}>
          {selectPeriod}
          {!isDeletable || <button className="flex justify-center w-full px-3 py-2 group hover:text-red hover:bg-red-light" onClick={() => { onClickPeriodSelect('delete'); }}><Icon className="w-4 h-4 text-black group-hover:text-red" name="trash" /></button>}
        </div>
      </Popover.Panel>
    </Popover>
  );
}

export default PeriodPopover;
