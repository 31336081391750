import React, {
 useState, useEffect, useReducer, useImperativeHandle, useRef,
} from 'react';
import { nanoid } from 'nanoid';
import { useTranslation } from '@/helpers/translate';
import { useSearchZustandStore } from '@/helpers/searchctx.js';
import Calendar from './content.js';
import dayjs from '@/helpers/dayjs';
import ClassCalendarStore from './store.js';
import Icon from '@/core/icon.js';

function FilterDate({ forwardRef }) {
  const [item] = useState({
    id: nanoid(),
    slug: 'dates',
    title: 'search:filter.date',
    flexibility: [
        {
            slug: '1',
            title: '1',
        },
        {
            slug: '3',
            title: '3',
        },
        {
            slug: '7',
            title: '7',
        },
    ],
  });
  const { t } = useTranslation();
  const [keySlug] = useState(item.id);
  // eslint-disable-next-line max-len
  const [flexValue, setFlexValue] = useState(0);
  // eslint-disable-next-line max-len
  const [calendarStore, setCalendarStore] = useState(new ClassCalendarStore({ datepicked: [], period: {} }));
  const [isOpenModal, setIsOpenModal] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsOpenModal(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef]);

  const convertToCalendarStore = (values) => (values || []).reduce((acc, { date, period }) => {
    acc.datepicked.push(dayjs(date.replace(/,000$/, '')).formatAsKey());
    acc.period[dayjs(date.replace(/,000$/, '')).formatAsKey()] = period;
    return acc;
  }, { datepicked: [], period: {} });

  const [currentValue, setCurrentValue] = useReducer((prev, { value }) => {
    let newValue = { ...prev };
    if (value) {
      newValue = { ...newValue, value };
    }
    newValue = { ...newValue, validValue: [...newValue.value] };

    return { ...newValue };
  }, {
    isChange: null,
    value: [],
    validValue: [],
  });

  const getValue = (from) => {
    if (from === 'url') {
      // eslint-disable-next-line max-len, no-unsafe-optional-chaining
      return currentValue?.validValue?.length ? currentValue?.validValue.reduce((acc, { date, period }) => {
        const newAcc = [...acc];
        newAcc.push(`${dayjs(date.replace(/,000$/, '')).format('YYYYMMDD')}-${period}`);
        return newAcc;
      }, []).join('|') + (flexValue ? `*${flexValue}` : '') : null;
    }
    return (currentValue?.validValue?.length && currentValue?.validValue) || null;
  };

  const getOutput = () => ({
    keySlug,
    slug: item.slug,
    url: getValue('url'),
    values: getValue(),
    graphql: {
      [item.slug]: {
        values: getValue(),
        ...(flexValue ? { flexibility: [parseInt(flexValue, 10)] } : {}),
      },
    },
    triggers: item.triggers,
  });

  const clearValue = (e) => {
    if (e) e.stopPropagation();
    calendarStore.reset();
    setCurrentValue({ value: [] });
  };

  function isSameDefault() {
    return !currentValue.validValue.length;
  }

  useImperativeHandle(forwardRef, () => ({
    getValue,
    getOutput,
    clearValue,
    setIsOpenModal,
    isSameDefault,
  }), [currentValue, flexValue]);

  function convertCalendarStoreToValidValue() {
    return Object.entries(calendarStore.getPeriod()).reduce((acc, [date, period]) => {
      const newAcc1 = [...acc];
      newAcc1.push({ date, period });
      return newAcc1;
    }, []);
  }

  const hasValue = () => currentValue?.validValue?.length;

  const clickValid = () => {
    setCurrentValue({ value: convertCalendarStoreToValidValue() });
    setIsOpenModal(false);
  };

  const selectFlex = (val) => {
    setFlexValue(flexValue === val ? null : val);
  };

  const renderTitle = () => (
    <div className="text-left block text-[14px] text-black pt-2 lg:pt-3 pb-0 px-4" onClick={() => setIsOpenModal(true)}>
      {t(item.title)}(s)
    </div>
  );

  const renderResume = () => {
    if (!(currentValue?.validValue || []).length) {
      return <div className="text-left md:text-lg text-dark">{t('core:when')}</div>;
    }
    return (
      <div className="flex justify-between">
        <div className="grid w-full grid-flow-col overflow-hidden text-black auto-cols-max gap-x-1" onClick={() => setIsOpenModal(true)}>
          {(currentValue?.validValue || []).map((v) => <div className="px-2 rounded lg:py-1 bg-white-dark2" key={`filterdate${v.date}`}>{dayjs(v.date.replace(/,000$/, '')).format('DD/M')}</div>)}
          {!flexValue || (
            <p className="flex items-center ml-1">
              <Icon name="plusminus" className="mx-1 text-black fill-current" />
              {flexValue}
              j
            </p>
          )}
        </div>
        <button className="w-6" onClick={(e) => { clearValue(e); }}><Icon name="close" className="w-full h-3 mx-1 fill-current text-dark" /></button>
      </div>
    );
  };

  const renderFlexibility = () => (
      <div className="">
        <div className="my-1 text-sm text-left lg:text-base text-dark ">Flexibilité</div>
        <div className="text-[14px] lg:text-[15px] grid grid-cols-3 gap-x-1">
          {(item?.flexibility || []).map((f) => (
            <span
              key={`cmpsearchfilterdateflex${f.slug}`}
              className={`text-center justify-center py-1 lg:py-2 px-3 flex items-center rounded cursor-pointer ${flexValue !== f.slug ? 'bg-white-dark hover:bg-white-dark2' : 'text-blue bg-blue-light hover:bg-blue-light'}`}
              onClick={() => selectFlex(f.slug)}
            >
              <Icon name="plusminus" className={`fill-current mx-1 ${flexValue !== f.slug || 'text-blue'}`} />
              {f.title}{' '}jour(s)
            </span>
          ))}
        </div>
      </div>
    );

  const renderContent = () => (
    <>
      <Calendar
        store={calendarStore}
        onSelected={() => {
        setCurrentValue({ value: convertCalendarStoreToValidValue() });
      }}
      />
      {renderFlexibility()}
      <button className="w-full px-3 py-2 mt-3 text-lg lg:hidden blue" onClick={() => { setIsOpenModal(false); }}>{t('btn:next')}</button>
    </>
  );

  const renderModal = () => (
    <>
      <div className="relative -top-[71px] lg:top-2" ref={modalRef}>
        <div className="w-full lg:min-w-[320px] rounded-lg bg-white absolute z-40 px-4 pb-5 pt-3 shadow-button">
          <div className="pt-0 text-sm lg:text-[15px]">{renderContent()}</div>
        </div>
      </div>
    </>
  );
  return (
    <>
      <div className="mb-2 border rounded-lg min-w-44 lg:relative border-white-dark4 shadow-light lg:border-none lg:shadow-none lg:rounded-none lg:mb-0 lg:border-b-0">
        {renderTitle()}
        <div className="bg-transparent lg:relative lg:bottom-[1px] lg:h-12 pt-0 pb-2 lg:pt-1 lg:pb-3 px-4 md:text-md block w-full border-none focus:ring-0" onClick={() => setIsOpenModal(true)}>
          {renderResume()}
        </div>
      </div>
      {!isOpenModal || renderModal()}
    </>
  );
}

export default FilterDate;
