/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
import type { categories_categories$ref } from "./categories_categories.graphql";
export type categoriesQueryVariables = {||};
export type categoriesQueryResponse = {|
  +$fragmentRefs: categories_categories$ref
|};
export type categoriesQuery = {|
  variables: categoriesQueryVariables,
  response: categoriesQueryResponse,
|};
*/


/*
query categoriesQuery {
  ...categories_categories
}

fragment categories_categories on Query {
  categories: getCategories {
    slug
    details
    id
  }
}
*/

const node/*: ConcreteRequest*/ = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "categoriesQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "categories_categories"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "categoriesQuery",
    "selections": [
      {
        "alias": "categories",
        "args": null,
        "concreteType": "Category",
        "kind": "LinkedField",
        "name": "getCategories",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "details",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2cc6a544c5d32a5b68485f5cefc7c7b3",
    "id": null,
    "metadata": {},
    "name": "categoriesQuery",
    "operationKind": "query",
    "text": "query categoriesQuery {\n  ...categories_categories\n}\n\nfragment categories_categories on Query {\n  categories: getCategories {\n    slug\n    details\n    id\n  }\n}\n"
  }
};
// prettier-ignore
(node/*: any*/).hash = '18970c56dc994b8cfad5ec5e1dcff0b1';

module.exports = node;
