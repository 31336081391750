import { useState } from 'react';
import dayjs from '@/helpers/dayjs';
import PeriodPopover from './periodPopover';

function ChoiceByDay({
 item, calendarCurrent = {}, store, onSelected,
}) {
  const [openPopover, setOpenPopover] = useState(false);

  const dateCurrent = dayjs(item.date);

  const fnPeriodAvailable = () => {
    const available = {
      d: true, m: true, a: true, n: true, disabled: '',
    };

    return available;
  };

  const fnDateIsSelected = () => store.getDatesPicked().indexOf(dateCurrent.formatAsKey()) > -1;
  // garbage function, juste for dev
  const getPeriodDateCurrent = () => store.getPeriod()[dateCurrent.formatAsKey()];

  const fnDateIsDisable = () => {
    // datecurrent isover
    if (store.isPast(dateCurrent)) return true;
    // datecurrent isn't same current month
    if (!dateCurrent.isSame(calendarCurrent.date, 'months')) return true;
    // day of week not filled
    return false;
  };

  const periodAvailable = fnPeriodAvailable();
  // if no available period => day disable, else check fnDateIsDisable
  const dateIsDisable = !(periodAvailable.disabled !== true) || fnDateIsDisable();
  const dateIsSelected = fnDateIsSelected();
  const classes = `cursor-pointer relative day text-center ${periodAvailable.disabled === true
    ? ''
    : periodAvailable.disabled}`;

  const onClickOpenPopover = () => {
    setOpenPopover(true);
  };

  function renderPopover() {
    if (!openPopover) return null;
    return (
      <PeriodPopover
        {...{ item, periodAvailable, store }}
        onClose={() => {
          onSelected?.();
          setTimeout(() => {
            setOpenPopover(false);
          }, 5);
        }}
      />
    );
  }

  return (
    <td
      data-selected={dateIsSelected}
      data-disabled={dateIsDisable}
      className={classes}
    >
      <div className="flex items-center justify-center">
        <div
          className={`flex period-${getPeriodDateCurrent()} items-center justify-center h-9 w-9 block rounded-lg hover:bg-white-dark ${openPopover ? 'bg-white-dark2' : ''} ${dateIsDisable ? 'font-normal text-white-dark4 bg-white' : ''}`}
          {...(dateIsDisable ? {} : { onClick: onClickOpenPopover })}
        >
          <span>
            {dateCurrent.date()}
            {dateIsSelected}
          </span>
        </div>
      </div>
      {renderPopover()}
    </td>
  );
}

export default ChoiceByDay;
